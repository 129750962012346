<script setup>
  import { ref, onMounted, inject } from 'vue'
  import { Swiper, SwiperSlide } from 'swiper/vue'

  import 'swiper/css'
  import 'swiper/css/pagination'
  import 'swiper/css/navigation'
  import { Autoplay, Pagination, Navigation } from 'swiper'

  const modules = [Autoplay, Pagination, Navigation]

  const store = inject('store')
  const slides = ref(['no-image.jpeg'])
  onMounted(async () => {
    let q = await fetch(store.state.api_url + 'get-slide?load')
    let r = await q.json()
    slides.value = Object.keys(r).length > 0 ? r : ['no-image.jpeg']
  })
</script>

<template>
  <swiper
    :slidesPerView="1"
    :spaceBetween="30"
    :centeredSlides="true"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
    }"
    :navigation="true"
    :modules="modules"
  >
    <swiper-slide v-for="(s, i) in slides" :key="i"><img :src="store.state.img_url + s" /></swiper-slide>
  </swiper>
</template>
<style>
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-prev,
  .swiper-button-next,
  .swiper-pagination-bullet .swiper-pagination-bullet-active {
    color: #fff !important;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 30px;
  }
</style>
