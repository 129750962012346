<script setup>
  import { ref, onMounted, inject } from 'vue'
  import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonImg,
    IonMenuButton,
    IonButtons,
    IonMenu,
    IonItem,
    IonLabel,
    IonList,
  } from '@ionic/vue'
  import { callOutline, mailOutline, logoInstagram, phonePortraitOutline } from 'ionicons/icons'
  import { RouterLink, useRouter } from 'vue-router'

  const year = new Date().getFullYear()
  const openLink = url => {
    window.open(url, '_blank', 'noreferrer')
  }

  const store = inject('store')
  const router = useRouter()
  const general = ref([])
  onMounted(async () => {
    try {
      let q = await fetch(store.state.api_url + 'general?load')
      let r = await q.json()
      general.value = Object.keys(r).length > 0 ? r : []
      localStorage.setItem('sh_nama', general.value.nama)
    } catch (error) {
      store.methods.modalBox('Kendala koneksi ke server', 'Error')
    }
  })
</script>
<template>
  <ion-page>
    <ion-menu content-id="container" class="mobile-menu">
      <ion-content class="ion-padding">
        <ion-list lines="none" class="ion-no-padding">
          <ion-item button @click="router.replace('/home')">
            <ion-label>Home</ion-label>
          </ion-item>
          <ion-item button @click="router.replace('/about')">
            <ion-label>About</ion-label>
          </ion-item>
          <ion-item button @click="router.replace('/rooms')">
            <ion-label>Rooms</ion-label>
          </ion-item>
          <ion-item button @click="router.replace('/amenities')">
            <ion-label>Facilities</ion-label>
          </ion-item>
          <ion-item button @click="router.replace('/contact')">
            <ion-label>Contact</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-content>
      <ion-header mode="md" class="ion-no-border">
        <ion-toolbar>
          <ion-grid class="desktop ion-no-padding">
            <ion-row class="ion-padding">
              <ion-col size="2">
                <ion-img :src="store.state.img_url + general.logo" @click="$router.push('/home')" />
              </ion-col>
              <ion-col size="4" class="ion-text-start"> </ion-col>
              <ion-col size="6">
                <ion-grid>
                  <ion-row class="menu">
                    <ion-col><router-link to="/home">Home</router-link></ion-col>
                    <ion-col><router-link to="/about">About</router-link></ion-col>
                    <ion-col><router-link to="/rooms">Rooms</router-link></ion-col>
                    <ion-col><router-link to="/amenities">Facilities</router-link></ion-col>
                    <ion-col><router-link to="/contact">Contact</router-link></ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-img :src="store.state.img_url + general.logo" slot="start" class="mobile" />
          <ion-buttons slot="end" class="mobile">
            <ion-menu-button :auto-hide="false"></ion-menu-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <div id="container">
        <slot />
      </div>

      <ion-footer class="ion-padding ion-no-border">
        <ion-grid>
          <ion-row>
            <ion-col size-md="4" size-xs="12">
              <h3 style="margin-bottom: 5px; padding-bottom: 0">{{ general.nama }}</h3>
              <div v-html="general.alamat"></div>
            </ion-col>
            <ion-col size-md="4" size-xs="12">
              <h3 style="margin-bottom: 10px">Contact Information</h3>
              <div>
                <span><ion-icon :icon="callOutline" /> {{ general.telp }}</span>
                <span><ion-icon :icon="phonePortraitOutline" /> {{ general.hp }}</span>
                <span><ion-icon :icon="mailOutline" /> {{ general.email }}</span>
                <span @click="openLink('https://www.instagram.com/' + general.instagram)" v-show="general.instagram != ''"
                  ><ion-icon :icon="logoInstagram" /> @{{ general.instagram }}</span
                >
              </div>
            </ion-col>
            <ion-col size-md="4" size-xs="12">
              <h3 class="ion-margin-bottom">Official Superapp</h3>
              <div class="mobile-app">
                <ion-img src="assets/img/get-android.webp" @click="openLink(general.android)" />
                <ion-img src="assets/img/get-ios.webp" @click="openLink(general.ios)" />
              </div>
            </ion-col>
            <ion-col size="12" class="ion-text-lg-end">
              <small>Copyright &copy; {{ year }} All Rights Reserved | {{ general.nama }}</small>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-footer>
    </ion-content>
  </ion-page>
</template>
<style scoped>
  ion-toolbar {
    --background: transparent;
    margin-bottom: -70px;
  }
  .desktop ion-col {
    text-align: center;
  }
  .desktop ion-img::part(image) {
    margin-top: -0.3rem;
    max-width: 110px;
  }

  .menu ion-col a {
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    text-shadow: 3px 0px 7px rgba(81, 67, 21, 0.8), -3px 0px 7px rgba(81, 67, 21, 0.8), 0px 4px 7px rgba(81, 67, 21, 0.8);
  }
  ion-menu-button {
    font-size: 2rem;
    --color: #cfc547;

    text-shadow: 3px 0px 7px rgba(81, 67, 21, 0.8), -3px 0px 7px rgba(81, 67, 21, 0.8), 0px 4px 7px rgba(81, 67, 21, 0.8);
  }
  ion-content {
    --background: none;
    background: radial-gradient(200.03% 117.51% at 135.28% -17.51%, #fedb37 11.01%, #9f7928 56.37%, #8a6e2f 100%);
    border-radius: 0;
    font-family: var(--font-family-sans-serif);
  }
  #container {
    min-height: 60%;
    background-color: #fff;
  }

  ion-footer {
    min-height: 3rem;
    color: #fff;
  }
  ion-footer div {
    color: rgb(226, 226, 226);
    font-size: 0.95rem;
    line-height: 1.5rem;
  }
  ion-footer div span {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 0.3rem;
  }
  ion-footer div span ion-icon {
    font-size: 1.3rem;
    margin-right: 0.5rem;
  }
  ion-footer h3 {
    font-weight: 600;
    color: rgb(226, 226, 226);
  }
  ion-footer ion-img::part(image) {
    max-width: 200px;
  }
  .mobile-app {
    display: flex;
    justify-content: space-around;
  }

  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    ion-img::part(image) {
      max-width: 80px;
      margin-left: 10px;
    }
    ion-footer ion-img::part(image) {
      max-width: 160px;
    }
  }
  .mobile-menu ion-list,
  .mobile-menu ion-item {
    --background: none;
    background: none;
    color: #fff;
  }
</style>
