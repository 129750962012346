import { createRouter, createWebHistory } from '@ionic/vue-router'
import HomePage from '@/views/HomePage.vue'

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/AboutPage.vue'),
  },
  {
    path: '/rooms',
    name: 'Rooms',
    component: () => import('@/views/RoomsPage.vue'),
  },
  {
    path: '/amenities',
    name: 'Amenities',
    component: () => import('@/views/AmenitiesPage.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/ContactPage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
