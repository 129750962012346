<script setup>
  import {
    IonCard,
    IonCardContent,
    IonImg,
    IonCardTitle,
    IonIcon,
    IonItem,
    IonLabel,
    IonGrid,
    IonCol,
    IonRow,
    IonSkeletonText,
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonContent,
  } from '@ionic/vue'
  import { ref, onMounted, inject } from 'vue'
  import { star, closeOutline } from 'ionicons/icons'

  import { Swiper, SwiperSlide } from 'swiper/vue'

  import 'swiper/css'
  import 'swiper/css/pagination'
  import 'swiper/css/navigation'

  import { Pagination, Navigation } from 'swiper'
  const modules = [Pagination, Navigation]

  const store = inject('store')
  const kamar = ref([])

  const loadKamar = async () => {
    try {
      store.state.loading = true
      let q = await fetch(store.state.api_url + 'get-kamar?loadKamar')
      let r = await q.json()
      store.state.loading = false

      kamar.value = Object.keys(r).length > 0 ? r : []
    } catch (error) {
      store.state.loading = false
      store.methods.alertShow('Kendala koneksi ke server. Mohon coba beberapa saat lagi.', 'danger')
    }
  }

  onMounted(() => {
    loadKamar()
  })

  const modalKamar = ref(false)
  const rincianKamar = ref([])
  const preview = ref('')
  const idKamar = ref(0)
  const slotKamar = ref(0)
  const showKamar = async idx => {
    try {
      let q = await fetch(store.state.api_url + 'get-kamar?getOne&id=' + kamar.value[idx].id)
      let r = await q.json()

      rincianKamar.value = Object.keys(r).length > 0 ? r : []
      preview.value = r.pic[0]
      idKamar.value = kamar.value[idx].id
      slotKamar.value = kamar.value[idx].slot
    } catch (error) {
      store.methods.alertShow('Kendala koneksi ke server', 'danger')
    }
    modalKamar.value = true
    rincianKamar.value.title = kamar.value[idx].judul
  }

  const setPreview = pic => {
    preview.value = pic
  }

  const bookNow = () => {
    //window.open('http://localhost:8101/referrer/' + idKamar.value + '/' + slotKamar.value)
    window.open('https://membership.cambridgehotelmedan.co.id/referrer/' + idKamar.value + '/' + slotKamar.value)
  }
</script>

<template>
  <div class="container">
    <div class="ion-text-center">
      <ion-icon :icon="star" v-for="n in 5" :key="n" style="color: gold; font-size: 0.8rem" />
      <h1>Explore Our Rooms</h1>
    </div>

    <swiper
      :slidesPerView="2"
      :spaceBetween="1"
      :slidesPerGroup="2"
      :pagination="{
        clickable: true,
      }"
      :breakpoints="{
        '640': {
          slidesPerView: 2,
          spaceBetween: 1,
        },
        '768': {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      }"
      :loop="true"
      :navigation="true"
      :modules="modules"
      v-if="store.state.loading === false"
    >
      <swiper-slide v-for="(k, i) in kamar" :key="i">
        <ion-card @click="showKamar(i)">
          <ion-img :src="store.state.img_url + k.pic" />
          <ion-card-content>
            <ion-card-title>{{ k.judul }} </ion-card-title>
            <div class="harga">
              <ion-item lines="none" class="ion-no-padding">
                <ion-label class="ion-no-margin"><small>Promo</small> IDR {{ store.methods.rupiah(k.harga_promo) }} </ion-label>
              </ion-item>
              <ion-item lines="none" class="ion-no-padding promo">
                <ion-label class="ion-no-margin"><small>Member</small> IDR {{ store.methods.rupiah(k.harga_member) }} </ion-label>
              </ion-item>
            </div>
          </ion-card-content>
        </ion-card>
      </swiper-slide>
    </swiper>

    <template v-else>
      <ion-grid>
        <ion-row>
          <ion-col size-md="6" v-for="n in 2" :key="n">
            <ion-card class="ion-no-border"> <ion-skeleton-text style="width: 100%; height: 150px" :animated="true" /> </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </template>
  </div>

  <ion-modal :is-open="modalKamar" :backdrop-dismiss="false">
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title>{{ rincianKamar.title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button fill="clear" @click="modalKamar = false"><ion-icon :icon="closeOutline" /></ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-img :src="store.state.img_url + preview" />
      <ion-grid>
        <ion-row>
          <ion-col v-for="pic in rincianKamar.pic" :key="pic" size="3" @click="setPreview(pic)">
            <ion-img :src="store.state.img_url + pic" />
          </ion-col>
        </ion-row>
      </ion-grid>
      <div style="height: 100%">
        <div v-html="rincianKamar.deskripsi" class="ion-padding" />

        <p style="height: 6rem; text-align: center">
          <ion-button color="medium" shape="round" @click="modalKamar = false">Tutup</ion-button>
          <ion-button color="warning" shape="round" @click="bookNow(i)">Book Now</ion-button>
        </p>
        <p style="height: 3rem; text-align: center">&nbsp;</p>
      </div>
    </ion-content>
  </ion-modal>
</template>
<style>
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide ion-card ion-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-pagination {
    margin-top: 1rem;
  }
  .swiper-pagination-bullet-active {
    background: gold;
  }
  .swiper-button-prev,
  .swiper-button-next {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  }
</style>
<style scoped>
  h1,
  ion-card-title {
    font-family: var(--font-family-serif);
  }
  h1 {
    margin-top: 5px;
    font-size: 2rem;
  }
  ion-card {
    border: 1px solid rgb(245, 245, 245);
    border-radius: 0;
    box-shadow: none;
  }
  ion-card-title {
    line-height: 1.5;
    color: #2e2e2e;
    font-weight: 400;
    font-size: 1.1rem;
  }

  .harga {
    display: flex;
    justify-content: space-between;
  }
  ion-item {
    --color: rgb(172, 4, 4);
    --border-color: rgb(245, 245, 245);
  }
  .promo {
    color: rgb(162, 139, 6);
  }

  ion-label small {
    display: block;
    font-weight: 500;
  }
  .container {
    margin: 2rem auto;
  }

  ion-content {
    scrollbar-width: none;
    overflow-y: scroll;
  }
  ion-content::-webkit-scrollbar {
    display: none;
  }
  ion-modal ion-title {
    font-size: 16px;
  }

  @media (min-width: 768px) {
    .container {
      width: 1150px;
    }
    .promo {
      text-align: right;
    }
  }
  @media (max-width: 768px) {
    ion-card-title {
      font-size: 1rem;
    }
    ion-label {
      font-size: 0.8rem;
    }
    .harga {
      display: block;
    }
  }
  p ion-button {
    width: 150px;
  }
</style>
