<script setup>
  import { IonApp, IonRouterOutlet } from '@ionic/vue'
  import { provide } from 'vue'
  import store from './store'

  provide('store', store)
</script>

<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>
