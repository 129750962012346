import { reactive } from 'vue'
import { toastController, alertController } from '@ionic/vue'
import { informationCircleOutline, closeCircleOutline } from 'ionicons/icons'

const base_url =
  window.location.protocol === 'http:' ? 'http://' + window.location.hostname + ':/chm-front/api/' : 'https://membership.cambridgehotelmedan.co.id/api/cms/'
const state = reactive({
  api_url: base_url,
  img_url:
    window.location.protocol === 'http:'
      ? 'http://' + window.location.hostname + ':/chm-membership/api/pics/'
      : 'https://membership.cambridgehotelmedan.co.id/api/pics/',
  key: localStorage.getItem('chm_session') || '',
  lvl: localStorage.getItem('chm_session') ? parseInt(localStorage.getItem('chm_session').slice(-2)) : 0,
  method: { method: 'post', body: JSON.stringify({ key: localStorage.getItem('chm_session') }) },
  loading: false,
})

const methods = {
  async alertShow(message, tipe) {
    const toast = await toastController.create({
      message: message,
      duration: 2500,
      icon: tipe === 'success' ? informationCircleOutline : closeCircleOutline,
      color: tipe,
      position: 'top',
      cssClass: 'custom-toast',
    })
    return toast.present()
  },
  async modalBox(message, title = '') {
    const mBox = await alertController.create({
      header: title,
      message: message,
      buttons: ['OK'],
      cssClass: 'selectText',
    })
    return mBox.present()
  },
  rupiah: t => new Intl.NumberFormat('id-ID').format(t),
}

export default {
  state,
  methods,
}
