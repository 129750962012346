<script setup>
  import { ref, inject, onMounted } from 'vue'
  import {
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonButton,
    IonIcon,
    IonCard,
    IonCardContent,
    IonImg,
    IonModal,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonContent,
    IonTitle,
  } from '@ionic/vue'
  import { calendarOutline, giftOutline, closeOutline } from 'ionicons/icons'

  const store = inject('store')
  const events = ref([])
  const promos = ref([])

  onMounted(async () => {
    try {
      let q = await fetch(store.state.api_url + 'promo?load')
      let r = await q.json()

      events.value = r.events
      promos.value = r.promos
    } catch (error) {
      store.methods.modalBox('Some of features are not loaded properly', 'Error')
    }
  })

  const openModal = ref(false)
  const dataModal = ref([])
  const lihatRincian = (idx, tipe) => {
    dataModal.value.judul = tipe === 'events' ? events.value[idx].judul : promos.value[idx].judul
    dataModal.value.foto = tipe === 'events' ? events.value[idx].foto : promos.value[idx].foto
    dataModal.value.isi = tipe === 'events' ? events.value[idx].isi : promos.value[idx].isi
    openModal.value = true
  }
</script>

<template>
  <div class="container">
    <div v-if="events.length > 0">
      <div class="ion-text-center">
        <ion-icon :icon="calendarOutline" style="color: gold; font-size: 1.8rem" />
        <h1 class="ion-margin-bottom ion-padding-bottom">Our Events</h1>
      </div>
      <ion-grid class="ion-no-padding ion-margin-bottom">
        <ion-row>
          <ion-col size-md="6" size="12" class="bg-cover" :style="'background-image: url(' + store.state.img_url + events[0].foto + ')'"> </ion-col>
          <ion-col>
            <ion-item lines="none" v-for="(e, i) in events" :key="i">
              <div slot="start" class="tgl">
                <span>{{ e.tgl }}</span> {{ e.bulan.toUpperCase() }}
              </div>
              <div>
                <h3>{{ e.judul }}</h3>
                <div v-html="e.isi" class="isi" />
                <ion-button color="warning" fill="outline" shape="round" @click="lihatRincian(i, 'events')">Detail</ion-button>
              </div>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="ion-text-center ion-margin-top ion-padding-top" v-if="promos.length > 0">
      <ion-icon :icon="giftOutline" style="color: gold; font-size: 1.8rem" />
      <h1 class="ion-margin-bottom ion-padding-bottom">Our Promos</h1>
      <ion-grid id="promo">
        <ion-row>
          <ion-col v-for="(p, i) in promos" :key="i" size-md="4" size-sm="6" size-xs="12">
            <ion-card @click="lihatRincian(i, 'promos')" class="ion-no-padding">
              <ion-img :src="store.state.img_url + p.foto" />
              <ion-card-content>
                <h3>{{ p.judul }}</h3>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <ion-modal :is-open="openModal" :backdrop-dismiss="false">
      <ion-content :fullscreen="true">
        <ion-header class="ion-no-border">
          <ion-toolbar>
            <ion-buttons slot="end">
              <ion-button @click="openModal = false"><ion-icon :icon="closeOutline" /></ion-button>
            </ion-buttons>
            <ion-title>{{ dataModal.judul }}</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-img :src="store.state.img_url + dataModal.foto" />
        <div v-html="dataModal.isi" class="ion-padding" />
        <p style="text-align: center">
          <ion-button color="dark" shape="round" @click="openModal = false">Tutup</ion-button>
        </p>
      </ion-content>
    </ion-modal>
  </div>
</template>
<style scoped>
  .container {
    margin: 4rem auto;
    background-color: #fafafa;
    padding: 2rem 0;
  }
  ion-col {
    background: #fff;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1.5rem;
  }
  .bg-cover {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .tgl {
    width: 70px;
    text-align: center;
    background-color: #fafafa;
    padding: 8px;
    font-size: 0.9rem;
    margin-top: -2.5rem;
  }
  .tgl span {
    font-size: 2rem;
    font-weight: 500;
    display: block;
    color: #ffc107;
  }
  h1,
  h2,
  h3 {
    font-family: var(--font-family-serif);
  }
  h1,
  h2 {
    margin-top: 5px;
    font-size: 2rem;
  }
  ion-item {
    margin-bottom: 2rem;
  }
  #promo ion-row {
    background: #fff;
  }

  #promo h3 {
    font-size: 1.1rem;
    color: #424242;
  }
  #promo ion-card {
    margin-inline: 0;
  }
  .isi {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 1rem;
  }
  ion-card {
    box-shadow: none;
  }
  ion-modal ion-title {
    font-size: 16px;
  }
</style>
